import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ComponentFactoryResolver, Inject, Injectable } from '@angular/core';
import { Problem } from 'app/common/model/problem';
import { StudyRecord } from 'app/common/model/study-record';
import { StudySubscription } from 'app/common/model/study-subscription';
import { Observable, of } from 'rxjs';
import { AppConfig, APP_CONFIG } from '../../app-config/app-config.module';
import { AncillaryStudy } from '../model/ancillary-study';
import { CaseProblem } from '../model/case-problem';
import { Code } from '../model/code';
import { DisplayCount } from '../model/display-count';
import { NotificationEmailDetail } from '../model/notification-email-detail';
import { NotificationSearchCriteria } from '../model/notification-search-criteria';
import { NotificationTileDetail } from '../model/notification-tile-detail';
import { PatientTileResult } from '../model/patient-tile-result';
import { StudyAccrualCount } from '../model/study-accrual-count';
import { StudySchemaEvent } from '../model/study-schema-event';
import { StudyStatusModel } from '../model/study-status-model';
import { StudyValidateRegistrationInterfaceResponse } from '../model/study-validate-registration-interface-response';
import { BaseService } from './base-service';



@Injectable()
export class StudyServiceBase extends BaseService {

    versionV2 = 'v2';
    http: HttpClient ;
    config: AppConfig;
    serviceUrlPrefix: string;
    serviceUrlPrefixV2: string;
    private version: string;


    constructor(http: HttpClient,
        @Inject(APP_CONFIG) config: AppConfig,
        componentFactoryResolver: ComponentFactoryResolver) {
        super(componentFactoryResolver);
        this.config = config;
        this.version = config.version;
        this.http = http;
        this.serviceUrlPrefix = config.apiEndpoint;
        this.serviceUrlPrefixV2 = this.config.v2_apiEndpoint;
    }

    /**
     * User clicks on specific study number to view more study details.
     *
     * @method getStudyDetails Gets study details in JSON and converts to StudyRecord and returns.
     * @param studyId
     * @returns StudyRecord This object contains full study details
     */
    public getStudy(studyId: number): Observable<StudyRecord> {
        if (this.version === this.versionV2) {
            return this.http.get<StudyRecord>(this.serviceUrlPrefixV2 + 'studies/' + studyId);
        } else {
            return this.http.get<StudyRecord>(this.serviceUrlPrefix + 'studies/' + studyId);
        }

    }

    

    /**
     * User clicks on specific study number to view more study details - calls the V2 endpoint.
     *
     * @method getStudyDetails Gets study details in JSON and converts to StudyRecord and returns.
     * @param studyId
     * @returns StudyRecord This object contains full study details for V2
     */
    public getStudyV2(studyId: number): Observable<StudyRecord> {
        return this.http.get<StudyRecord>(this.serviceUrlPrefixV2 + 'studies/' + studyId);
    }

    /**
     * This will delete the study and its associated relationship.
     * It throws error , if there are any patients on that study.
     *
     * @param  number studyId - studyId
     * @param  number tupleVersion - tupleVersion of the study
     * @returns Observable
     */
    public deleteStudy(studyId: number, tupleVersion: number): Observable<any> {
        const header = new HttpHeaders().set('Content-Type', 'application/json').set('If-Match', tupleVersion.toString());
        const myUrl = this.serviceUrlPrefix + 'studies/' + studyId
        return this.http.delete(myUrl, { headers: header, responseType: 'text' });
    }


    /**
     * Returns the ancillary study information
     * @param studyId The Ancillary Study Id
     */
    getAncillaryStudy(studyId: number): Observable<AncillaryStudy> {
        return this.http.get<AncillaryStudy>(this.serviceUrlPrefix + 'ancillary-studies/' + studyId);
    }


    /**
     * Updates the ancillary study
     * @param record The ancillary study to update
     */
    updateAncillaryStudy(record: AncillaryStudy): Observable<AncillaryStudy> {
        // https://dev.reg-backend.mayo.edu/api/v1/ancillary-studies
        const url = this.serviceUrlPrefix + 'ancillary-studies';
        return this.http.put<AncillaryStudy>(url, record);
    }

    /**
     * Gets the Codes for lookup for Ancillary Study Patient Enrollment
     */
    getAncillaryPatientEnrollmentCodes(): Observable<Code[]> {
        return this.http.get<Code[]>(this.serviceUrlPrefix + 'configuration/patient-enrollment-indicator');
    }

    /**
     * Retrieves the study subscriptions details.
     *
     * @param studyId
     * @returns StudySubscription Contains subscription details
     */
    public getSubscriptionInfo(studyId: number): Observable<StudySubscription> {
        return this.http.get<StudySubscription>(this.serviceUrlPrefix + 'studies/' + studyId + '/subscriptions');
       // return of({ "distributionId": 1, "tupleVersion": 1, "subscribe": true });
    }


    /**
     * Updates the subscription info for the study and user
     * @param studyId The studyId associated with this notification
     * @param studySubscription the studySubscription object being updated
     */
    public updateSubscription(studyId, studySubscription: StudySubscription): Observable<any> {
        const header = new HttpHeaders().set('Content-Type', 'application/json').set('userId', 'reguiUser@mayo.edu');
        const url = this.serviceUrlPrefix + 'studies/' + studyId + '/subscriptions';
        //   return this.http.put(url, studySubscription, {headers:header});
        return this.http.put(url, studySubscription);
    }

    /**
     * Returns the count of the active patients for this study
     * @param studyId The study Id for the active Patients
     */
    public getActivePatientCount(studyId: number): Observable<DisplayCount> {
       return this.http.get<DisplayCount>(this.serviceUrlPrefix + 'studies/' + studyId + '/patients/active-count');
    }


    /**
     * Returns the count of the accruals for this study
     * @param studyId The study Id for the accruals
     */
    public getAccruals(studyId: number): Observable<StudyAccrualCount> {
        // let result: StudyAccrualCount = { currentAccrual: 21, totalAccrual: 276, expectedAccrualMonth: 5 };
       // return of(result);
       return this.http.get<StudyAccrualCount>(this.serviceUrlPrefix + 'studies/' + studyId + '/accruals');
    }

    /**
     * Returns the count of the accruals based on the schema event that has the "Accrual Count" attribute set
     * URL is /studies/{studyId}/accruals/event
     * @param studyId The study Id for the accruals
     */
    public getAccrualsForAccrualEvent(studyId: number): Observable<StudyAccrualCount> {
    //     const result: StudyAccrualCount = { currentAccrual: null, totalAccrual: 276, expectedAccrualMonth: 5 };
    //    return of(result);
       return this.http.get<StudyAccrualCount>(this.serviceUrlPrefix + 'studies/' + studyId + '/accruals/event');
    }

    /**
     * Returns the studyStatuses from the service
     */
    public getStudyStatuses(studyType: string): Observable<StudyStatusModel[]> {
       return this.http.get<StudyStatusModel[]>(this.serviceUrlPrefix + 'studies/statuses?studyType=' + studyType);
    }

    public validateStudyAttributeRegistrationInterface(studyId: number): Observable<StudyValidateRegistrationInterfaceResponse> {
        return this.http.get<StudyValidateRegistrationInterfaceResponse>(this.serviceUrlPrefix + 'studies/' + studyId + '/validate/registration-interface');
    }


    /**
     * Gets recent 5 studies from backend.
     *
     * @method getRecentStudies Gets list of studies in JSON and converts to list of StudyRecord and returns.
     * @returns Array<StudyRecord> List of full study details objects
     */
    public getRecentStudies(): Observable<Array<StudyRecord>> {
        return this.http.get<Array<StudyRecord>>(this.serviceUrlPrefix + 'studies/recent');
    }

    /**
     * Returns the count of the available studies for the home page non-admin studies tile
     */
    public getAvailableStudiesCount(): Observable<DisplayCount> {
            return this.http.get<DisplayCount>(this.serviceUrlPrefixV2 + 'studies/available/count');
     }

     /**
     *  Gets my available studies from backend.
     *
     * @method getAvailableStudies Gets list of studies in JSON and converts to list of StudyRecord and returns.
     * @returns Array<StudyRecord> List of full study details objects
     */
    public getAvailableStudies(): Observable<Array<StudyRecord>> {
            return this.http.get<Array<StudyRecord>>(this.serviceUrlPrefixV2 + 'studies/available');
    }


     /**
     * Get studies under construction
     *
     * @returns studies under construction
     * @memberof StudyLandingService
     */
    public getStudiesUnderConstruction(): Observable<StudyRecord[]> {
        if (this.version === this.versionV2) {
            return this.http.get<StudyRecord[] >(this.serviceUrlPrefixV2 + 'studies?status=p');
        } else {
            return this.http.get<StudyRecord[] >(this.serviceUrlPrefix + 'studies?status=p');
        }

    }


     /**
     * Get active patients count
     *
     * @returns number of in progress patient records
        */
    public getActivePatientCountForAllStudies(): Observable<number> {
        return this.http.get<number >(this.serviceUrlPrefix + 'users/active-patients/count');
    }

    /**
     * Get active patients
     *
     * @returns patients with in progress patient records
        */
       public getActivePatientsForAllStudies(): Observable<PatientTileResult[]> {
           if (this.version === this.versionV2) {
            return this.http.get<PatientTileResult[] >(this.serviceUrlPrefixV2 + 'users/active-patients');
           } else {
            return this.http.get<PatientTileResult[] >(this.serviceUrlPrefix + 'users/active-patients');
           }

    }


    public getNotificationSearch(notificationSearchCriteria: NotificationSearchCriteria ): Observable<NotificationTileDetail[]> {
        const url = this.serviceUrlPrefix +  'notifications/search'   ;
        return this.http.post<NotificationTileDetail[]>(url, notificationSearchCriteria);
    }
    /**
     * This will fetch the number of patients with case event/problems.
     * @param  number studyId - studyId
     * @returns Observable - return count.
     */
    getCaseProblemCount(studyId: number): Observable<DisplayCount> {
        return this.http.get<DisplayCount>(this.serviceUrlPrefix + 'studies/' + studyId + '/patients/case-events/count');
    }

    /**
     * This will fetch case event problems (CEvents).
     * @param  number studyId - studyId
     * @returns Array<CaseProblem> - list of CEvents.
     */
    public getCaseProblems(studyId: number):  Observable<CaseProblem[]> {
        return this.http.get<CaseProblem[] >(this.serviceUrlPrefix + 'studies/' + studyId + '/patients/case-events');
    }

   /**
    * delete case event problem
    * @param studyId
    * @param caseProblemId
    * @param tupleVersion
    */
    public deleteCaseProblem(studyId: number, caseProblemId: number, tupleVersion: number):   Observable<any> {
        const header = new HttpHeaders().set('Content-Type', 'application/json').set('If-Match', tupleVersion.toString());
        const url = this.serviceUrlPrefix + 'studies/' + studyId + '/patients/case-events/' + caseProblemId;
        return this.http.delete(url, { headers: header, responseType: 'text' });
    }

    /**
     * This will update case event problem (CEvent).
     * @param  number studyId - studyId
     */
    public updateCaseProblem(studyId: number, record: CaseProblem ): Observable<CaseProblem> {
        const url = this.serviceUrlPrefix + 'studies/' + studyId + '/patients/case-events'   ;
        return this.http.put<CaseProblem>(url, record);
    }

     /**
     * This will add case event problem (CEvent).
     * @param  number studyId - studyId
     * @returns Array<CaseProblem> - list of CEvents.
     */
    public addCaseProblem(studyId: number, record: CaseProblem ): Observable<CaseProblem> {
        const url = this.serviceUrlPrefix + 'studies/' + studyId + '/patients/case-events'   ;
        return this.http.post<CaseProblem>(url, record);
    }


     /**
     * This will get problem code list.
     * @returns Array<Problem> - list of problem codes.
     */
    public getProblems( ): Observable<Problem[]>  {
        const url = this.serviceUrlPrefix + 'patients/case-problem-types';
        return this.http.get<Problem[]>(url);
    }


    /**
     * Returns the count of Ancillary studies for a given study
     * @param studyId The studyID to get the count of
     */
    public getAncillaryStudyCount(studyId: number): Observable<DisplayCount> {
        const url = this.serviceUrlPrefix + 'studies/' + studyId + '/ancillary/count';
        return this.http.get<DisplayCount>(url);
    }


     /**
     * This will get email detail for given emailLogId.  Used for recent notifications preview pane link.
     * @returns NotificationEmailDetail - email detail to be displayed
     */
     public getNotificationEmailDetail(emailLogId: number): Observable<NotificationEmailDetail> {
        const url = this.serviceUrlPrefix + 'notifications/email/' + emailLogId;
        console.log('getNoficiationEmailDetail URL: ', url);
        return this.http.get<NotificationEmailDetail>(url);
    //     let results : NotificationEmailDetail =
    //       {"emailLogId":1, "fromAddress":"from1@yahoo.com", "recipients":["to1@yahoo.com", "to2@yahoo.com"], "subject": "Test Subject", "body": "Email Body 1"};
    //   console.log("returning ", results);
    //   return of(results);

    }

      /**
     * Retrieves the study reservation status
     *
     * @param studyId
     * @returns StudySchemaEvent Contains reservation event's studyschemaEventId and status
     */
       public getReservationEventStatus(studyId: number): Observable<StudySchemaEvent> {
        return this.http.get<StudySchemaEvent>(this.serviceUrlPrefix + 'studies/' + studyId + '/reservation-events/check');
    }
}
